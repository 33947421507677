import {
  MaterialAnalysisSource,
  ErrorTypes,
  WarningTypes,
  MappedClassification,
  ChangePasswordError,
  ExportRfidGroupsError,
  RfidChipType,
} from "./../../api/graphql/generated/globalTypes"
import {
  AbilityName,
  InvalidityType,
  WER_ERROR_CODE,
  Rating,
  TextPlaceholderKey,
  Frequency,
  ClassificationSelector,
  ExternalTownType,
  TourType,
} from "../../api/graphql/generated/globalTypes"
import { AdvancementPDFButtonStatus } from "../../components/partials/pdf/AdvancementPDFButton"
import { MaterialDistributionEnum } from "../../constants/Constants"

export const de = {
  admin: "Admin",
  denovo: "Denovo",
  language: "Sprache",
  request_timeout: "Der Netzwerkzugriff hat zu lange gedauert",
  request_failed: "Der Netzwerkzugriff ist fehlgeschlagen",
  daheim: "Daheim",
  symvaro: "Symvaro",
  privacy_policy_link: "https://www.ecotec-scantec.com/privacy-policy",
  terms_and_conditions_link:
    "https://afb96ab4-d9ff-4610-86b4-d36fd6d3650e.usrfiles.com/ugd/afb96a_8e4027a8de434bd5abe0345f62c670bb.pdf",
  languages: {
    de: "Deutsch",
    en: "English",
    sl: "Slovenščina",
  },
  tour_types: {
    [TourType.organic]: "Biomüll",
    [TourType.residual]: "Restmüll",
  },
  classifier: "Klassifikator",
  menu: {
    dashboard: "Dashboard",
    analysis: "Ergebnisanalyse",
    pdf: "PDF Erstellung",
    communication_center: "Kommunikationscenter",
    tours: "Tourenplanung",
    association_management: "Verbände & Gemeinden",
    housing_managements_management: "Hausverwaltungen",
    rfid_management: "RFIDs",
    citizen_management: "Bürger",
    user_management: "Benutzer",
    login: "Anmelden",
    text_management: "Texte",
    vehicle_management: "Fahrzeuge",
    statistics: "Statistikauswertung",
    eda: "EDA",
    log: "Log",
    file_templates: "Vorlagen",
    rfid_groups: "RFID Gruppen pro Haus",
  },
  login_page: {
    email: "Email",
    password: "Passwort",
    login: "Login",
    continue: "Weiter",
    data_privacy_statement: "Datenschutzerklärung",
    terms_and_conditions: "AGB",
    imprint: "Impressum",
    error_title: "Hinweis",
    forgot_password: "Passwort vergessen?",
    password_reset_error: "Passwort zurücksetzen fehlgeschlagen",
    password_reset_success: "Passwort Mail wurde versandt",
    password_reset_dialog: {
      title: "Passwort vergessen",
      cancel: "Abbrechen",
      confirm: "Passwort zurücksetzen",
    },
  },
  reports_page: {
    heading: "Ergebnisberichte",
  },
  tour_page: {
    create_tour: "Tour erstellen",
    import_tours: "Touren importieren",
    heading: "Tourenplanung",
    errors: {
      duplicate: "Tour existiert bereits",
      endtime_before_starttime: "Startzeit muss vor Endzeit sein.",
    },
    warnings: {
      overlapping_entry_date:
        "Es gibt bereits eine Tour mit den selben Daten: Verband, Gemeinde, Fraktion, Fahrzeug und Datum.",
      overlapping_entry_date_time: "Die Tourzeiten überschneiden sich mit einer anderen Tour.",
    },
    import_rfid_readings: "RFID Lesungen importieren",
    import_tour_readings_dialog: {
      title: "RFID Lesungen importieren",
      association_label: "Verband",
      town_label: "Gemeinde",
      import: "Importieren",
      import_failed: "Import fehlgeschlagen:",
      invalid_rfids: " - Folgende RFIDs sind nicht in der Gemeinde registriert: '{{invalidRfids}}'",
      invalid_wastescanner_ids: " - Folgende Fahrzeuge sind ungültig: '{{invalidWastescannerIds}}'",
      invalid_format: "- Überprüfen Sie die Header und Daten der Datei.",
      success:
        "Import erfolgreich: Es wurden {{countImported}} RFID Lesungen importiert und {{countDuplicates}} übersprungen (Duplikate)",
      close: "Schließen",
      daheim_info: "Es können nur RFID Lesungen in Daheim Gemeinden importiert werden.",
    },
    filter: {
      has_analysis: "Analysedaten vorhanden",
      has_rfid_reader: "RFID Leser vorhanden",
      has_data: "vorhanden",
      doesnt_have_data: "nicht vorhanden",
    },
  },
  dashboard_page: {
    heading: "Dashboard",
    tour_section: {
      heading: "Tourenplanung",
      no_data: "Es gibt in dieser KW keine Touren!",
    },
    vehicle_section: {
      //TODO: ADD KW to the beginning once there is enough data
      heading: "LKWs",
      no_data: "Keine LKWs gefunden!",
    },
  },
  analysis_page: {
    heading: "Wertstoffscanner Ergebnisanalyse",
    no_data_for_this_filter: "Es gibt keine Daten für diese Filtereinstellung",
    no_data_for_housing_management: "Keine Daten für Ihre Hausverwaltung vorhanden",
    filter_classification_default: "Standard",
    filter_classification_auto: "Nur Automatisch",
    filter_classification_manually: "Nur Manuell",
    composition_heading: "Zusammensetzung {{type}} für gefilterten Bereich",
    quality_of_seperation: "Trennqualität",
    pictures: "Bilder",
    invalid_pictures: "Nicht klassifizierbare Bilder",
    adjusted_date_to_emptying: "Datum an Entleerwoche angepasst",
    analysis_could_not_be_deleted: "Analyse konnte nicht gelöscht werden",
    no_available_associations: "Ihnen sind keine Verbände oder Gemeinden zugewiesen.",
    filter: {
      association: "Verband",
      town: "Gemeinde",
      empty_week: "Entleerwoche",
      empty_week_last_10_days: "letzte 10 Tage",
      waste_type: "Abfallart",
      percent_of: "Prozent von",
      percent_to: "Prozent bis",
      period_from: "Zeitraum von",
      period_to: "Zeitraum bis",
      classification: "Klassifizierung",
      rating: "Bewertung",
      from: "Von",
      to: "Bis",
      waste_scanner: "WasteScanner",
      source: "Herkunft",
      rfids: "RFIDs",
    },
    list: {
      classification: "Klassifizierung",
      bag_amount: "Säcke",
      bag_amount_description: "wird wie loser Müll bewertet",
    },
    classificationSelector: {
      [ClassificationSelector.ALL]: "ALLE",
      [ClassificationSelector.STANDARD]: "Standard",
      [ClassificationSelector.MANUAL]: "Nur Manuell",
      [ClassificationSelector.AUTOMATIC]: "Nur Automatisch",
    },
    fractions: {
      miss_sortings: "Fehlwürfe",
      not_visible: "Nicht sichtbar",
      [MaterialDistributionEnum.Residual]: "Restmüll",
      [MaterialDistributionEnum.Organic]: "Biomüll",
      [MaterialDistributionEnum.Battery]: "Batterien",
      [MaterialDistributionEnum.BlownBottle]: "Hohlkörper",
      [MaterialDistributionEnum.Glass]: "Glas",
      [MaterialDistributionEnum.Hazardous]: "gefährlicher Abfall",
      [MaterialDistributionEnum.Metal]: "Metall",
      [MaterialDistributionEnum.PET]: "PET",
      [MaterialDistributionEnum.Paper]: "Papier",
      [MaterialDistributionEnum.Spraycan]: "Spraydosen",
      [MaterialDistributionEnum.Plastic]: "Plastik",
      [MaterialDistributionEnum.PlasticOther]: "Leichtverpackung",
      [MaterialDistributionEnum.GarbageBagOther]: "sonstiger Sack",
      [MaterialDistributionEnum.GarbageBagTransp]: "durchsichtiger Sack",
      [MaterialDistributionEnum.GarbageBagYellow]: "gelber Sack",
      [MaterialDistributionEnum.GarbageBagOrganic]: "abbaubarer Sack",
    },
    seperation_quality_history: "Trennqualität im Verlauf",
    history: "Verlauf",
    miss_sortings_quota: "Fehlwurfanteil",
    tout_type_title_quota: "{{type}}anteil",
    filter_tourtype_name: "Hauptfraktion",
    filter_tourtype_residual: "Restmüll",
    filter_tourtype_organic: "Biomüll",
    advancement_pdf: {
      section_title: "PDF Export",
      number_of_images: "Anzahl an Bilder: {{numberOfItems}}",
      disclaimer: "Es wurden {{numberOfItems}} Bilder zu dieser Filter-Einstellung gefunden.",
      terms_and_condition:
        "Es liegt in der Eigenverantwortung des Portalnutzers die bestehenden datenschutzrechtlichen Bestimmungen einzuhalten.",
      gps_accuracy:
        "Bevor Sie fortfahren, möchten wir Sie weiters darauf hinweisen, dass die GPS-Auflösung ungenau sein kann.",
      button: {
        [AdvancementPDFButtonStatus.creatingPdf]: "Wird erstellt...",
        [AdvancementPDFButtonStatus.failed]: "Fehlgeschlagen",
        [AdvancementPDFButtonStatus.notStarted]: "Erstellen",
        [AdvancementPDFButtonStatus.readyToDownload]: "Herunterladen",
        menu: {
          create_zip: "ZIP erstellen",
          create_pdf: "PDF erstellen",
        },
      },
      tooltip_loading: "Bitte haben Sie etwas Geduld.",
      ratings_error: "min. eine Bewertung auswählen",
      timespan_info: "Es wird empfohlen, als Zeitspanne nicht mehr als 2-3 Wochen auszuwählen.",
      timespan_error: "Zeitspanne zu lang (max. 6 Monate)",
    },
    waste_quality: {
      heading: "Mülltrenn Qualität",
      num_of_invalid: "Anzahl der nicht klassifizierbaren Bilder: {{count}}",
    },
  },
  general: {
    show_more: "mehr Anzeigen",
    main_fraction: "Hauptfraktion",
    missthrow: "Fehlwurf",
    waste: "Müll",
    send: "Senden",
    test: "Test",
    add: "Hinzufügen",
    wastescanner: "Wertstoffscanner",
    wastescanner_portal: "Wertstoffscanner Portal",
    logout: "Abmelden",
    cancel: "Abbrechen",
    data_loading: "Daten werden geladen...",
    details: "Details",
    towns: "Gemeinden",
    town: "Gemeinde",
    latitude: "Längengrad",
    longitude: "Breitengrad",
    radius: "Radius",
    edit: "Bearbeiten",
    delete: "Löschen",
    remove: "Löschen",
    create: "Erstellen",
    daheim_town: "Daheim Gemeinde",
    symvaro_town: "Symvaro Gemeinde",
    search: "Suche",
    reports: "Berichte",
    associations: "Verbände",
    associations_and_towns: "Verbände & Gemeinden",
    no_data_available_reload_page: "Es ist ein Fehler aufgetreten, bitte laden sie die Seite neu",
    no_data_for_this_searchterm: "Keine Daten für diesen Suchbegriff gefunden",
    created_at: "Erstellt am {{date}}",
    category: "Kategorie",
    rating: "Bewertung",
    loading: "Laden",
    download: "Herunterladen",
    error: "Fehler",
    excel_export: "Excel Export",
    CW: "KW",
    to: "bis",
    convert: "Umwandeln",
    duplicate: "Duplizieren",
    success: "Erfolg",
    save: "Speichern",
    confirm: "Bestätigen",
    diagram: "Diagramm",
    apply: "Anwenden",
    please_choose: "Bitte auswählen...",
    no: "Nein",
    yes: "Ja",
    ok: "OK",
    manually: "manuell",
    custom: "Benutzerdefiniert",
    default: "Standard",
    yes_delete: "Ja, löschen",
    list_loading: "Liste wird geladen ...",
    please_wait: "Bitte um ein wenig Geduld",
    to_few_data_diagram: "Zu wenige Daten um ein Diagramm anzuzeigen.\n",
    please_change_filter: "Bitte verändern sie den Filter.",
    bookmark: "Lesezeichen",
    bookmarks: "Lesezeichen",
    name: "Name",
    edited: "bearbeitet",
    channel: "Channel",
    reupload: "Erneut hochladen",
    close: "schließen",
    all: "Alle",
    trucks: "LKWs",
    stats: "Statistik",
    clear: "Filter aufheben",
    rating_schema: "Bewertungsschema",
    day: "Tag",
    day_plural: "Tage",
    housing_management: "Hausverwaltung",
  },
  bookmark: {
    save_bookmark: "Lesezeichen speichern",
    save_new_bookmark: "als neues Lesezeichen anlegen",
    delete_bookmark: "Lesezeichen löschen",
    create_bookmark: "Lesezeichen erstellen",
    successfully_created: "Lesezeichen wurde erstellt!",
    successfully_updated: "Lesezeichen wurde gespeichert!",
    no_bookmarks_found: "Es wurde noch kein Lesezeichen erstellt!",
  },
  communication_channels: {
    daheim_app: "Daheim App",
    symvaro: "Symvaro",
    newspaper: "Zeitung",
    black_board: "Schwarzes Brett",
    sms: "SMS",
    new_component: "Neuer Baustein",
    choose_component: "Baustein auswählen",
    email: "Email",
  },
  table: {
    no_data: "Keine Daten verfügbar",
    previous: "Zurück",
    next: "Weiter",
    page: "Seite",
    rows: "Einträge",
    of: "von",
  },
  analysis_detail: {
    title: "Erfasste Fraktionen",
    image_information: "Bildinformationen",
    more: "Normal",
    less: "Fraktionen",
    invalid_image_title: "Ungültiges Bild:",
    date_time: "Datum / Uhrzeit",
    image_number: "Bildnummer",
    rfid_number: "RFID-Nummer",
    delete_dialog: {
      heading: "Analyse löschen",
      text: "Möchten Sie diese Analyse löschen?",
    },
    invalidate_analysis: {
      title: "Analyse invalidieren",
      invalidate: "Invalidieren",
      reason: "Begründung",
      cancel: "Abbrechen",
    },
  },
  tour: {
    types: {
      residual: "Restmüll",
      organic: "Biomüll",
    },
    property: {
      town: "Gemeinde",
      towns: "Gemeinden",
      association: "Verband",
      tourType: "Fraktion",
      date: "Datum",
      startTime: "Start",
      endTime: "Ende",
      wasteScanner: "Wertstoffscanner",
      rfidReader: "RFID Leser",
      geocept_device: "GeoCept Gerät (IMEI)",
      analysis_data: "Analysedaten",
      is_splitted: "3 Bildanalyse",
      timePeriod: "Uhrzeit",
    },
    tableColumn: {
      analysis_count: "# Analysen",
      analysis_count_split_all: "# Analysen (Ganz)",
      analysis_count_valid: "# Analysen (Gültig)",
      analysis_has_rfid_readings: "Rfid Lesungen",
      action: "Bearbeiten",
    },
    dialog: {
      create: {
        title: "Neue Tour erstellen",
        button: "Erstellen",
      },
      create_anyway: {
        title: "Tour trotzdem erstellen?",
        button: "Trotzdem Erstellen",
      },
      update: {
        title: "Tour bearbeiten",
        button: "Speichern",
        time_info: "Die Tour muss vor {{min}} anfangen bzw. nach {{max}} enden.",
      },
      update_anyway: {
        title: "Tour trotzdem bearbeiten?",
        button: "Trotzdem Speichern",
      },
      delete: {
        button: "Endgültig Löschen",
      },
      delete_tour_data: {
        title: "RFID Lesungen & Analysen löschen",
        title_deleted: "RFID Lesungen & Analysen wurden gelöscht",
        text: "Möchtest du wirklich alle RFID Lesungen für diese Tour löschen?",
        text_deleted:
          "{{rfidReadingsCount}} Rfid Lesung(en) und {{materialAnalysisCount}} Analyse(n) wurden erfolgreich gelöscht!",
        button: "Löschen",
        delete_material_analysis_too: "Bilder und Analysen löschen",
      },
    },
  },
  validation: {
    time: {
      notpossible: "Zeit ist nicht möglich",
    },
    date: {
      notpossible: "Datum ist nicht möglich",
    },
  },
  text_management: {
    heading: "Textverwaltung",
    add_category: "Kategorie",
    please_choose_category: "Bitte wählen Sie eine Kategorie aus",
    add_template: "Textvorlage hinzufügen",
    no_templates_available: "Keine Textvorlagen verfügbar",
    freetext: "Freitext",
    placeholder: "Platzhalter",
    text_editor: "Texteditor",
    preview: "Vorschau",
    default: "Standardvorlage",
    dialog: {
      create: {
        title: "Textvorlage erstellen",
        button: "Erstellen",
      },
      update: {
        title: "Textvorlage bearbeiten",
        button: "Speichern",
      },
      delete: {
        heading: "Vorlage löschen",
        text: "Möchten Sie dieses Template löschen?",
        confirmText: "Löschen",
      },
    },
  },

  external_town_type: {
    [ExternalTownType.daheim]: "Daheim",
    [ExternalTownType.symvaro]: "Symvaro",
  },
  text_placeholder: {
    [TextPlaceholderKey.NEW_LINE_SINGLE]: "Zeilenumbruch",
    [TextPlaceholderKey.NEW_LINE_DOUBLE]: "Leerzeile",
    [TextPlaceholderKey.TOUR_TYPE]: "Hauptfraktion",
    [TextPlaceholderKey.TOWN_NAME]: "Gemeinde",
    [TextPlaceholderKey.MAIN_MISSTHROW_1_NAME]: "1. Hauptstörstoff",
    [TextPlaceholderKey.MAIN_MISSTHROW_2_NAME]: "2. Hauptstörstoff",
    [TextPlaceholderKey.MISSTHROW_PERCENTAGE]: "Fehlwurfanteil",
    [TextPlaceholderKey.MISSTHROW_PER_TOWN_PERCENTAGE]: "Fehlwurfanteil (Gemeinde)",
    [TextPlaceholderKey.RFID]: "RFID(s)",
    [TextPlaceholderKey.MATERIAL_ANALYSIS_LIST]: "Schüttungen (Liste)",
    [TextPlaceholderKey.INVALID_TOTAL_COUNT]: "Anzahl Unklassifizierbare",
    [TextPlaceholderKey.INVALID_REASON]: "Grund der Unklassifizierbarkeit",
    preview: {
      [TextPlaceholderKey.NEW_LINE_SINGLE]: "\n",
      [TextPlaceholderKey.NEW_LINE_DOUBLE]: "\n\n",
      [TextPlaceholderKey.TOUR_TYPE]: "Restmüll",
      [TextPlaceholderKey.TOWN_NAME]: "Feldkirchen",
      [TextPlaceholderKey.MAIN_MISSTHROW_1_NAME]: "Plastik",
      [TextPlaceholderKey.MAIN_MISSTHROW_2_NAME]: "Glas",
      [TextPlaceholderKey.MISSTHROW_PERCENTAGE]: {
        [Rating.High]: "1",
        [Rating.Medium]: "14",
        [Rating.Low]: "50",
        [Rating.Unknown]: "14",
      },
      [TextPlaceholderKey.RFID]: "303030303",
      [TextPlaceholderKey.MATERIAL_ANALYSIS_LIST]:
        "Musterstraße 3, Musterdorf 0000 (12.12.2022)\nMusterstraße 6, Musterdorf 0000 (12.12.2022)",
      [TextPlaceholderKey.MAIN_MISSTHROW_2_NAME]: "Glas",
      [TextPlaceholderKey.MISSTHROW_PER_TOWN_PERCENTAGE]: "14",
      [TextPlaceholderKey.INVALID_TOTAL_COUNT]: "5",
      [TextPlaceholderKey.INVALID_REASON]: "Es war nur Schokolade im Müll",
    },
  },
  association_management: {
    heading: "Verbandsverwaltung",
    add_association: "Verband hinzufügen",
    add_town: "Gemeinde hinzufügen",
    no_towns: "Es gibt keine Gemeinden für diesen Veband.",
    enable_custom_rating_schema: "Analyse Bewertungsschema manuell festlegen",
    dialog: {
      create: {
        title: "Verband erstellen",
        button: "Erstellen",
      },
      update: {
        title: "Verband bearbeiten",
        button: "Speichern",
      },
      delete: {
        button: "Endgültig Löschen",
      },
    },
    town_dialog: {
      create: {
        title: "Gemeinde erstellen",
        button: "Erstellen",
      },
      update: {
        title: "Gemeinde bearbeiten",
        button: "Speichern",
      },
      delete: {
        button: "Endgültig Löschen",
      },
      link_with_town_boundaries: "Mit Geofence verlinken",
      link_with_town_boundaries_info: "Beim Upload von Analysen werden diese automatisch mit der Gemeinde verlinkt.",
      no_linked_town_linked: "Keine Gemeinde zum verlinken ausgewählt",
      successfully_linked_with_town: "Mit {{townName}} verlinken",
      create_as_external_town: "Mit externer Gemeinde verknüpfen",
      only_manually_analysis: "Benutzern nur manuell klassifizierte Daten anzeigen",
      label_info: "Als Administrator können Sie alle Daten einsehen.",
    },
  },
  housing_management: {
    heading: "Hausverwaltungen",
    add_housing_management: "Hausverwaltung hinzufügen",
    no_housing_managements: "Keine Hausverwaltungen gefunden",
    towns: "Gemeinden",
    associations: "Verbände",

    rfids: {
      title: "RFIDs",
      button: "RFIDs anzeigen",
    },
    dialog: {
      create: {
        title: "Hausverwaltung erstellen",
        button: "Erstellen",
      },
      update: {
        title: "Hausverwaltung bearbeiten",
        button: "Speichern",
      },
      delete: {
        button: "Löschen",
      },
      close: {
        button: "Schließen",
      },
    },
    property: {
      name: "Name",
    },
  },
  citizen_management: {
    heading: "Bürgerverwaltung",
    no_citizens: "Es gibt keine Bürger in dieser Gemeinde.",
    dialog: {
      update: {
        title: "Bürger bearbeiten",
        button: "Speichern",
      },
      delete: {
        button: "Endgültig Löschen",
      },
    },
    import_citizens: "Bürger importieren",
    import_citizens_dialog: {
      title: "Bürger importieren",
      association_label: "Verband",
      town_label: "Gemeinde",
      import: "Importieren",
      close: "Schließen",
      success: "Bürger wurden erfolgreich importiert.",
      unknown_error: "Es ist ein unbekannter Fehler aufgetreten.",
      daheim_info: "Es können nur Bürger in Daheim Gemeinden importiert werden.",
    },
    export_citizens: "Bürger exportieren",
    export_citizens_dialog: {
      title: "Bürger exportieren",
      association_label: "Verband",
      town_label: "Gemeinde",
      export: "Exportieren",
      close: "Schließen",
      success: "Bürger wurden erfolgreich exportiert.",
      unknown_error: "Es ist ein unbekannter Fehler aufgetreten.",
      daheim_info: "Es können nur Bürger in Daheim Gemeinden exportiert werden.",
    },
  },
  user_management: {
    heading: "Benutzerverwaltung",
    add_user: "Benutzer hinzufügen",
    associations_and_towns: "Verbände und Gemeinden",
    housing_managements: "Hausverwaltungen",
    vehicles: "Fahrzeuge",
    assign_all_towns: "alle Gemeinden zuweisen",
    all_towns_assigned: "alle Gemeinden",
    select_single_towns_info: "Sie können Gemeinden auch einzeln zuweisen",
    set_town_as_default:
      "Als Heimatgemeinde festlegen - Eine Heimatgemeinde wird beim Einstieg ins Portal beim Analysefilter vorausgewählt",
    dialog: {
      create: {
        title: "Benutzer erstellen",
        button: "Erstellen",
      },
      update: {
        title: "Benutzer bearbeiten",
        button: "Speichern",
      },
      delete: {
        button: "Endgültig Löschen",
      },
      info_password_set: "Passwort wurde bereits gesetzt.",
      info_password_not_set: "Passwort wurde noch nicht gesetzt.",
      link_reset_password: "Email zum Setzen des Passworts an Benutzer senden",
    },
    ability: {
      [AbilityName.admin]: "Admin",
      [AbilityName.manager]: "Verwalter",
      [AbilityName.tugraz]: "TU Graz",
      [AbilityName.area_manager]: "Bereichsadmin",
      [AbilityName.advancement_pdf]: "PDF für Bewertungen",
      [AbilityName.town_boundaries]: "Geofence",
      [AbilityName.housing_management]: "Hausverwalter",
      [AbilityName.waste_composition]: "Fehlwurfzusammensetzung",
      [AbilityName.fraction_images]: "Fraktionsbilder",
    },
  },
  town: {
    property: {
      name: "Gemeindename",
      latitude: "Längengrad",
      longitude: "Breitengrad",
      radius: "Radius",
      zipCode: "PLZ",
      classification: "Klassifizierung",
      classification_auto: "automatisch",
      classification_manually: "manuell",
      classification_unset: "-",
    },
  },
  association: {
    property: {
      name: "Verband",
      latitude: "Längengrad",
      longitude: "Breitengrad",
      radius: "Radius",
      document_deletion_range_in_days: "Dokumente löschen nach",
      sms_sender: "SMS Versand",
      default_sender: "normaler Absender",
      alphanumeric_sender: "alphanumerischer Absender",
    },
  },
  citizen: {
    property: {
      firstName: "Vorname",
      lastName: "Nachname",
      rfid: "RFID {{number}}",
      rfids: "RFIDs",
      code: "Code",
      address: "Adresse",
      phone1: "TelefonNr. 1",
      phone2: "TelefonNr. 2",
      email: "E-Mail Adresse",
    },
  },
  user: {
    property: {
      email: "Email",
      name: "Name",
      towns: "Gemeinden",
      associations: "Verbände",
      abilities: "Rollen",
      default_town: "Heimatgemeinde",
    },
  },
  communication_center: {
    heading: "Kommunikationscenter",
    no_reports: "Es wurden keine Reports gefunden.",
    overall_rating: "Durchschnittliche Bewertung",
    create_a_report_from_an_analysis: "Erstelle den ersten Report aus einer Analyse.",
    text_individual_widget: {
      dialog: {
        heading: "Individueller Text",
        text_category: "Textkategorie",
      },
    },
    last_sent: "zuletzt gesendet",
    no_history_entry: "noch nicht gesendet",
    last_post_failed: "Ein Fehler ist beim letzten Sendeversuch aufgetreten.",
  },
  post_to_daheim_dialog: {
    heading: "An {{externalTownType}} Newsfeed schicken",
    error: "Es ist ein Fehler aufgetreten, der Bericht konnte nicht veröffentlicht werden.",
  },
  password_reset: {
    heading: "Passwort zurücksetzen",
    new_password: "Neues Passwort",
    new_password_again: "Neues Passwort bestätigen",
    set_password: "Passwort setzen",
    back_to: "Zurück zum",
    login: "Login",
    accept_privacy_policy: "Ich habe die <1>Datenschutzerklärung</1> und <3>AGB</3> gelesen und akzeptiert.",
  },
  api_error: {
    65616: "Dieser RFID-Chip existiert nicht.",
    65622: "Sie haben keine Berichtigung für diese Aktion.",
    1002: "Tour überlappt mit anderer Tour!",
    1011: "Das Bewertungsschema ist ungültig",
    1020: "Der Kommunikationskanal konnte nicht verifiziert werden.",
    2001: "Der Titel ist zu lang (max. 200 Zeichen)",
    3001: "Der Link ist nicht mehr gültig!",
    8102: "{{entity}} existiert bereits!",
    8202: "{{entity}} existiert nicht!",
    7601: "Versenden der SMS fehlgeschlagen. Überprüfen Sie die Telefonnummer",
    7603: "Versenden der Email fehlgeschlagen. Überprüfen Sie die Email",
    change_password: {
      [ChangePasswordError.NOT_ENOUGH_DIFFERENCT_CHARACTERS]:
        "Das Passwort muss mindestens einen Groß- und einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen beinhalten.",
      [ChangePasswordError.SAME_PASSWORD]: "Sie dürfen nicht das gleiche Passwort wie bisher verwenden!",
      [ChangePasswordError.TOO_SHORT]: "Das Passwort muss mindestens aus 8 Zeichen bestehen.",
    },
    export_rfid_groups: {
      [ExportRfidGroupsError.NO_RFID_GROUPS_FOR_TOWN]: "Es gibt in dieser Gemeinde keine RFID Gruppen.",
    },
  },
  WER_ERROR_CODE: {
    [WER_ERROR_CODE.SUCCESS]: "Erfolg",
    [WER_ERROR_CODE.OTHER]: "Unbekannter Fehler",
    [WER_ERROR_CODE.ABILITY_MISSING]: "Fehlende Rechte",
    [WER_ERROR_CODE.SIGN_IN__EMAIL_UNKNOWN]: "Email ist falsch.",
    [WER_ERROR_CODE.SIGN_IN__WRONG_PASSWORD]: "Passwort ist falsch.",
    [WER_ERROR_CODE.USER__INVALID_INPUT]: "Eingaben sind nicht richtig",
    [WER_ERROR_CODE.USER__EMAIL_EXISTS]: "Diese Email existiert bereits",
    [WER_ERROR_CODE.DUPLICATE]: "Eintrag existiert bereits.",
  },
  waste_quality: {
    [Rating.Unknown]: "Nicht klassifizierbar",
    [Rating.Low]: "Schlecht",
    [Rating.Medium]: "Mittel",
    [Rating.High]: "Gut",
  },
  waste_quality_short: {
    [Rating.Unknown]: "U",
    [Rating.Low]: "S",
    [Rating.Medium]: "M",
    [Rating.High]: "G",
  },
  report: {
    create_report: "Bericht erstellen",
    description_title: "Geben Sie dem Bericht einen Titel damit er später leichter auffindbar ist.",
    title: "Titel",
    delete: "Bericht löschen",
    delete_description: "Möchten Sie den Bericht {{report}} wirklich löschen?",
    free_text: "Freitext",
    enter_free_text: "Freitext eingeben",
    overall_rating: "Durchschnittliche Bewertung",
    widget_not_recognised: "Unbekannter Baustein",
    transform_graph: "Diagramm umwandeln",
  },
  widget_type: {
    title_text: "Text",
    generic_title_text: "Title",
    title_pie_chart: "Tortendiagramm",
    title_pie_chart_generic: "Diagramm",
    title_pie_chart_waste_quality: "Tortendiagramm Mültrenn Qualität",
    title_pie_chart_waste_quality_generic: "Diagramm",
    title_history_chart: "Verlaufsdiagramm",
    title_history_chart_generic: "Diagramm",
    title_trend_chart: "Trenddiagramm Gemeinde",
    title_trend_chart_generic: "Diagramm",
    title_chart_trend_chart_comparison: "Trenddiagramm",
    title_trend_chart_comparison_generic: "Diagramm",
    title_individual_text: "Individueller Text",
    choose_text_title: "Text auswählen",
    choose_text_info: "Bitte wählen Sie einen Text aus.",
  },
  graph: {
    percent: "%-Anteil",
    trend: {
      heading: "Trenddiagramm Gemeinde ({{townName}})",
      tooltip: {
        max: "Maximum",
        min: "Minimum",
        trend: "Trend",
        overall: "Durchschnitt",
      },
    },
    comparison_trend: {
      heading: "Trenddiagramm Verband ({{associationName}})",
      tooltip: {
        max: "Maximum",
        min: "Minimum",
        trend: "Trend",
        overall: "Durchschnitt",
      },
      select_towns:
        "Bitte wählen Sie mindestens eine Gemeinde aus. Es können maximal drei Gemeinden gleichzeitig ausgewählt werden.",
    },
  },
  sms_dialog: {
    heading: "SMS senden",
    receiver: "Empfänger",
    when_send: "Wann möchten Sie die SMS senden?",
    date: "Datum",
    time: "Uhrzeit",
    warning_select_every_lane: "Bitte wählen Sie einen Text in jeder Spalte aus!",
    amount_receiver: "Anzahl der Empfänger: {{amount}}",
    good_news_amount: "Gute Nachrichten: {{amount}}",
    medium_news_amount: "Mittlere Nachrichten: {{amount}}",
    bad_news_amount: "Schlechte Nachrichten: {{amount}}",
    general_news_amount: "Allgemeine Nachrichten: {{amount}}",
    success_message_amount: "Es wurde(n) {{amount}} Nachricht(en) versendet.",
    success_message: "Es wurde eine Nachricht versendet.",
    test_info_text: "Der ausgewählte Text wird unverändert an die folgende Telefonnummer versendet.",
    test_info_texts:
      "Die Vorlagen werden mit zufälligen Daten aus der Datenbank befüllt und an die folgende Telefonnummer versendet.",
    rfid: "RFID",
    rfid_group: "RFID Gruppe",
  },
  email_dialog: {
    heading: "Email senden",
    receiver: "Empfänger",
    when_send: "Wann möchten Sie die Email senden?",
    date: "Datum",
    time: "Uhrzeit",
    warning_select_every_lane: "Bitte wählen Sie einen Text in jeder Spalte aus!",
    amount_receiver: "Anzahl der Empfänger: {{amount}}",
    good_news_amount: "Gute Nachrichten: {{amount}}",
    medium_news_amount: "Mittlere Nachrichten: {{amount}}",
    bad_news_amount: "Schlechte Nachrichten: {{amount}}",
    general_news_amount: "Allgemeine Nachrichten: {{amount}}",
    success_message_amount: "Es wurde(n) {{amount}} Nachricht(en) versendet.",
    success_message: "Es wurde eine Nachricht versendet.",
    test_info_text: "Der ausgewählte Text wird unverändert an die folgende E-Mail-Adresse versendet.",
    test_info_texts:
      "Die Vorlagen werden mit zufälligen Daten aus der Datenbank befüllt und an die folgende E-Mail-Adresse versendet.",
    rfid: "RFID",
    rfid_group: "RFID Gruppe",
  },
  excel_export: {
    excel_export: "Excel Export",
    filter: "Filter",
    no_filter_set: "Keine Filter gesetzt",
    main_fraction: "Hauptfraktion",
    wrong_fractions: "Fehlerhafte Fraktionen",
    address_unknown: "Unbekannt",
    export_unmapped: "mit allen Fraktionen exportieren",
    export_mapped: "mit zusammengefassten Fraktionen exportieren",
    export: {
      organization: "Organisation",
      overall_rating: "Durchschnittliche Bewertung",
      wertstoff: "Wertstoff",
      amount: "Anteil",
      missthrow: "Fehlwürfe",
      date: "Datum",
      gps: "GPS",
      classification: "Klassifizierung",
      total_miss_sortings: "Gesamte Fehlwürfe",
      address: "Adresse",
      rfid: "RFID",
      file: "Dateiname",
      rating: "Bewertung",
      invalid: "Ungültig",
    },
  },
  errors: {
    unknown: "Unbekannter Fehler",
    generic1: "Ups, ein Fehler trat auf!",
    generic2: "Das hätte nicht passieren dürfen!",
    no_reports: "Keine Reports",
    no_reports2: "Erstelle den ersten Report aus einer Analyse.",
    endtime_before_starttime: "Startzeit muss vor Endzeit sein.",
  },
  import_info: {
    error_types: {
      [ErrorTypes.HEADER_INCORRECT]: "Bitte überprüfen Sie die Kopfzeile (erste Zeile) der Excel-Datei",
      [ErrorTypes.TIME_SPAN_INCORRECT]:
        "Zeile {{line}}: Bitte überprüfen Sie die Zeiten ({{date}} {{time1}}-{{time2}})",
      [ErrorTypes.TIME_FORMAT_INCORRECT]:
        "Zeile {{line}}: Bitte überprüfen Sie die Datum/Zeitangabe ({{date}}, {{time1}}, {{time2}})",
      [ErrorTypes.ASSOCIATION_NOT_FOUND]: "Zeile {{line}}: Gemeinde ({{association}}) wurde nicht gefunden",
      [ErrorTypes.TOWN_NOT_FOUND]: "Zeile {{line}}: Verband ({{town}}) wurde nicht gefunden",
      [ErrorTypes.FRACTION_NOT_FOUND]: "Zeile {{line}}: Fraction ({{fraction}}) wurde nicht gefunden",
      [ErrorTypes.DUPLICATE]: "Zeile {{line}}: Datensatz existiert bereits",
      [ErrorTypes.ENTRY_INCOMPLETE]: "Zeile {{line}}: Enthält leere Spalten",
      [ErrorTypes.WASTESCANNER_NOT_FOUND]: "Zeile {{line}}: Wertstoffscanner ({{wastescanner}}) wurde nicht gefunden",
      [ErrorTypes.RFID_NOT_MATCHING_TOWN]:
        "Zeile {{line}}: RFID ({{rfid}}) ist nicht in der gewählten Gemeinde registriert",
      [ErrorTypes.RFID_NOT_FOUND]: "Zeile {{line}}: RFID ({{rfid}}) wurde nicht gefunden",
      [ErrorTypes.BOOLEAN_VALUE_INCORRECT]: "Zeile {{line}}: Wert ist ungültig! (Ja/Nein)",
      [ErrorTypes.NO_ACCESS_TO_VEHICLE]:
        "Zeile {{line}}: Sie haben keinen Zugriff auf dieses Fahrzeug. ({{wasteScannerId}})",
      [ErrorTypes.PREFERRED_COMMUNICATION_CHANNEL_VALUE_INCORRECT]:
        'Zeile {{line}}: Wert für bevorzugten Komminationskanal ist ungültig! (SMS/E-Mail oder beides mit "," getrennt)',
    },
    warning_types: {
      [`${WarningTypes.OVERLAPPING_ENTRY}_1`]: "Zeile {{line}}: Datensatz überlappt sich mit einem Eintrag in der Datenbank",
      [`${WarningTypes.OVERLAPPING_ENTRY}_2`]: "Zeile {{line}}: Datensatz überlappt sich mit dem Eintrag in Zeile {{line2}}",

      [WarningTypes.CODE_FOR_RFID_UPDATED]:
        'Zeile {{line}}: Code für RFID ({{rfid}}) wurde aktualisiert: "{{originalCode}}" --> "{{newCode}}"',
      [WarningTypes.RFID_ALREADY_EXISTS]: "Zeile {{line}}: RFID existiert bereits und wird beim Import überschrieben",
      [WarningTypes.RFID_NOT_FOUND]: "Zeile {{line}}: RFID ({{rfid}}) wurde nicht gefunden",
      [WarningTypes.RFID_IMPORTED_IN_OTHER_TOWN]:
        'Zeile {{line}}: RFID ({{rfid}}) wurde in Gemeinde "{{town}}" importiert.',
      [WarningTypes.RFID_FOUND_IN_OTHER_TOWN]: 'Zeile {{line}}: RFID ({{rfid}}) wurde in Gemeinde "{{town}}" gefunden.',
      [WarningTypes.UNKNOWN_RFID_CHIP_TYPE]:
        'Zeile {{line}}: Containertyp ({{type}}) ist ungültig. Typ "Sonstiges" wird beim Import verwendet.',
    },
  },
  import_tours_dialog: {
    error_file_selector: "Bitte wählen sie eine Excel-File, dessen Dateigröße maximal 2 MB beträgt",
    move_files: "Datei hier herziehen oder",
    drop_files: "",
    select_files: "klicken für Auswahl",
    import_success_heading: "Import erfolgreich",
    success: "Es wurden {{number}} Touren erfolgreich importiert.",
    error: "Beim Importieren ist ein Fehler aufgetreten.",
    import: "Importieren",
    import_info:
      "Bitte vor dem Import Fahrzeuge anlegen und darauf achten, dass RFID-Leser Nummer, falls vorhanden, angegeben ist, da diese nicht automatisch aus den Daten zum Fahrzeug übernommen wird.",
    import_nevertheless: "Trotzdem importieren",
    file_rejected: "Dieses Dateiformat wird nicht unterstützt.",
  },
  widget_text_categories: {
    rating_with_missthrow_percentage: "Bewertung mit Fehlwurfanteil",
    rating_with_main_missthrow: "Bewertung mit Hauptstörstoff",
    black_board: "Schwarzes Brett",
    compare_with_town: "Vergleich des aktuellen Filters mit gesamter Gemeinde",
  },
  vehicle_management: {
    heading: "Fahrzeugverwaltung",
    create_vehicle: "Fahrzeug erstellen",
    dialog: {
      create: {
        title: "Fahrzeug erstellen",
        button: "Erstellen",
      },
      update: {
        title: "Fahrzeug bearbeiten",
        button: "Speichern",
      },
      delete: {
        button: "Endgültig Löschen",
      },
    },
  },
  vehicle: {
    property: {
      wasteScannerId: "Wertstoffscanner",
      rfidReader: "RFID Leser",
      number_of_tours: "Tourenanzahl",
      geocept_device: "GeoCept Gerät (IMEI)",
      rfidReadingToMaterialAnalysisOffset: {
        min: "min Latenz RFID Lesung - Analyse",
        max: "max Latenz RFID Lesung - Analyse",
      },
      geoCeptToMaterialAnalysisOffset: {
        min: "min Latenz GeoCept - Analyse",
        max: "max Latenz GeoCept - Analyse",
      },
      image_usage_info: "{{used}} von {{available}}",
      image_usage: "Bildverbrauch",
      image_limit: "Bilderlimit",
    },
  },
  material_analysis: {
    invalidity_type: {
      [InvalidityType.GENERAL]: "Ein anderer Fehler ist aufgetreten ({{reason}})",
      [InvalidityType.BAG_AMOUNT]: "Der Anteil der Müllsäcke ist zu hoch: >66%",
      [InvalidityType.BRIGHTNESS]: "Es ist zu hell/dunkel",
      [InvalidityType.CURTAIN]: "Der Vorhang verdeckt die Wertstoffe",
      [InvalidityType.DUST]: "Zuviel Staub",
      [InvalidityType.PRESS]: "Die Presse war im Bild",
      [InvalidityType.NOT_INVALID]: "Kein Problem",
      [InvalidityType.SPLIT_NOT_VALIDATED]: "Die Analyse wurde noch nicht verifiziert.",
      [InvalidityType.BY_USER]: "Die Analyse wurde manuell als Ungültig markiert. (Grund: {{reason}})",
    },
  },
  statistics: {
    heading: "Wertstoffscanner Statistikauswertung",
    invalid_images_title: "Ungültige Bilder",
    overview: {
      text: {
        total: "{{total}} aufgenommene Bilder",
        valid: "{{valid}} auswertbare Bilder",
        invalid: "{{invalid}} nicht auswertbare Bilder",
        refine_results: "Ergebnisse verfeinern",
      },
    },
    excel_export: {
      file_name: "Wertstoffscanner Statistik",
    },
    diagram: {
      title: "Diagramm",
      total: "aufgenommen",
      valid: "auswertbar",
      invalid: "nicht auswertbar",
    },
  },
  frequency: {
    [Frequency.daily]: "Täglich",
    [Frequency.weekly]: "Wöchentlich",
    [Frequency.monthly]: "Monatlich",
  },
  pdf_page: {
    heading: "PDF Erstellung",
  },
  eda_page: {
    heading: "Event Driven Architecture",
    approximateNumberOfMessages_tooltip: "Im Moment befindet sich ein Job in der Queue.",
    approximateNumberOfMessages_tooltip_plural: "Im Moment befinden sich {{count}} Jobs in der Queue.",
    approximateNumberOfMessagesNotVisible_tooltip: "Im Moment wird ein Job verarbeitet",
    approximateNumberOfMessagesNotVisible_tooltip_plural: "Im Moment werden {{count}} Jobs verarbeitet",
    delaySeconds_tooltip: "Ein Job wartet ca. {{count}} Sekunde in der Queue, bis er verabeitet wird.",
    delaySeconds_tooltip_plural: "Ein Job wartet ca. {{count}} Sekunden in der Queue, bis er verabeitet wird.",
  },
  file_template_page: {
    heading: "Dateivorlagen",
  },
  rfid_management: {
    heading: "RFID-Verwaltung",
    import_rfids: "RFIDs Importieren",
    export_rfids: "RFIDs Exportieren",
    no_rfids: "Keine RFIDs vorhanden",
    dialog: {
      update: {
        title: "RFID Bearbeiten",
      },
      delete: {
        title: "RFID Löschen",
        content: "Sind Sie sicher?",
      },
    },
    import_dialog: {
      title: "RFIDs Importieren",
      close: "Schließen",
      import: "Importieren",
      import_anyway: "Trotzdem Importieren",
      success: "RFIDs wurden erfolgreich importiert!",
    },
    export_dialog: {
      title: "RFIDs Exportieren",
      close: "Schließen",
      export: "Exportieren",
      success: "RFIDs wurden erfolgreich exportiert!",
      association_label: "Verband",
      town_label: "Gemeinde",
      file_name: "RFID_Chips",
    },
    unassignable_addresses: {
      resolve: "Beheben",
      info: "In dieser Gemeinde gibt es {{numUnassignableAddresses}} unzugewiesene Adressen.",
      dialog: {
        title: "Unzugewiesene Adressen",
        export_addresses: "Unzugewiesene Adressen exportieren",
      },
    },
    delete_error: {
      EXISTING_ANALYSIS: "RFID kann nicht gelöscht werden. Material Analyse existiert bereits!",
      EXISTING_CITIZENS_CONNECTION: "RFID kann nicht gelöscht werden. Verbindung zu Bürgern existiert bereits!",
      EXISTING_RFID_READING: "RFID kann nicht gelöscht werden. RFID Lesung existiert bereits!",
    },
  },
  unassignable_address: {
    property: {
      address: "Adresse",
      comment: "Kommentar",
    },
  },
  rfid: {
    property: {
      rfid: "RFID",
      address: "Adresse",
      code: "Code",
      uniquely_assignable: "Eindeutig Zuordenbar",
      citizens: "Bürger",
      comment: "Kommentar",
      type: "Containertyp",
      types: {
        [RfidChipType.SA1100L]: "SA1100L",
        [RfidChipType.SA120L]: "SA120L",
        [RfidChipType.SA240L]: "SA240L",
        [RfidChipType.SA360L]: "SA360L",
        [RfidChipType.SA770L]: "SA770L",
        [RfidChipType.SA80L]: "SA80L",
        [RfidChipType.Sonstiges]: "Sonstiges{{comment}}",
        [RfidChipType.T60]: "60",
        [RfidChipType.T80]: "80",
        [RfidChipType.T120]: "120",
        [RfidChipType.T240]: "240",
        [RfidChipType.T360]: "360",
        [RfidChipType.T660]: "660",
        [RfidChipType.T770]: "770",
        [RfidChipType.T1100]: "1100",
      },
    },
  },
  rfid_groups: {
    heading: "RFID Gruppen pro Haus",
    export_rfid_groups: "RFID Gruppen exportieren",
    dialog: {
      create: {
        title: "RFID Gruppe erstellen",
        create_button: "Gruppe erstellen",
        rfid_field: "RFID hinzufügen",
        undefined_address_info: "Nach Eingabe einer validen Adresse könne Sie hier RFIDs zu Ihrer Gruppe hinzufügen.",
        invalid_address_info: "Eine RFID Gruppe für die angegebene Addresse ist bereits vorhanden!",
      },
      update: {
        title: "RFID Gruppe bearbeiten",
        update_button: "Gruppe aktualisieren",
      },
      delete: {
        title: "RFID Gruppe löschen",
        content: "Wollen Sie die RFID Gruppe wirklich löschen?",
      },
      create_default: {
        title: "Automatische RFID Gruppen erstellen",
        content: "Wollen Sie wirklich RFID Gruppen pro Haus mit mehr als einer RFID erstellen?",
        no_new_groups: "Es wurden keine neuen RFID Gruppen für diese Gemeinde erstellt.",
      },
      export: {
        title: "RFID Gruppen Exportieren",
        close: "Schließen",
        export: "Exportieren",
        success: "RFID Gruppen wurden erfolgreich exportiert!",
        association_label: "Verband",
        town_label: "Gemeinde",
        file_name: "RFID_Gruppen",
      },
      rfid_collection_title: "Gruppen RFIDs",
    },
    add_rfid_group_button: "RFID Gruppe hinzufügen",
    create_default_rfid_groups_button: "Automatische RFID Gruppen hinzufügen",
    property: {
      address: "Adresse",
      rfids: "RFIDs",
      street: "Straße",
      streetNumber: "Hausnr.",
      city: "Stadt",
      postalcode: "PLZ",
    },
    no_rfidgroups: "Keine RFID Gruppen verfügbar.",
  },
  material_analysis_source: {
    [MaterialAnalysisSource.geocept]: "GeoCept",
    [MaterialAnalysisSource.rfid]: "RFID",
    [MaterialAnalysisSource.other]: "Sonstige",
  },
  detailed_address: {
    property: {
      street_number: "Hausnr.",
      street: "Straße",
      city: "Stadt",
      postalcode: "PLZ",
    },
  },
  log: {
    heading: "Log",
    show_message: "Nachricht anzeigen",
    filter: {
      date_from: "Zeitraum von",
      date_until: "Zeitraum bis",
    },
    property: {
      time: "Timestamp",
      userEmail: "Benutzer",
      receiver: "Gesendet an",
      citizen: "Bürger",
      message: "Nachricht",
    },
  },
  mapped_classification: {
    label: "Klassifizierung",
    save: "Speichern",
    [MappedClassification.AUTOMATICALLY]: "Automatisch",
    [MappedClassification.MANUALLY]: "Manuell",
    [MappedClassification.ORIGINAL]: "Default {{originalValue}}",
  },
  verify_communication_channel: {
    verified_successfully: "Dieser Kommunikationskanal wurde erfolgreich verifiziert.",
    to_login: "Zum Login",
  },
  fraction_assignment: {
    assign_fractions: "Fraktionen zuweisen",
    use_custom_fraction_mapping: "Eigene Fraktionszuweisung verwenden",
    main_fraction: "Hauptfraktion",
  },
}
